export const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
})

export const NumberToString = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  minimumFractionDigits: 1,
})

export const splitByCapitalLetter = (str: string) =>
  str.split(/(?=[A-Z])/).join(' ')
